const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;


// import Swiper from 'swiper';
// import Swiper, { Navigation, Autoplay, Pagination } from 'swiper';

// window.Swiper = Swiper;

import IonRangeSlider from 'ion-rangeslider';
import { videosHandler } from './modules/videos';
import { uiHandler } from './modules/ui';
import { mobileHandler } from './modules/mobile';
// import { ajaxFilters2 } from './modules/ajaxFilters';
// import 'swiper/css';
// import * from 'datatables.net-dt';

const mobileBreakpoint = 768;
const headerBreakpoint = 1024;

window.$ = window.jQuery = jQuery;
// window.Swiper = Swiper;
// document.addEventListener("DOMContentLoaded", function () {

/***********************************
 MAIN
***********************************/
let mainHandler = {

	init: function () {

		// Swiper.use([Navigation, Autoplay, Pagination]);

		mobileHandler.init();
		uiHandler.init();

		this.stylingHandler();
		this.customForms();
		this.burgerNav();
		this.stickyNav();
		// this.productsSliders();
		// this.informationsAccordeons();
		// this.wooCart();
		this.wishlist();
		this.actionQuoteMail();
		this.ajaxFilters();

		this.backToTop();

		this.checkoutEvents();
		this.modalLogin();
		// this.trophyBottomBanner();

		videosHandler.init();

	},

	modalLogin: function () {
		let $modalLogin = document.querySelector('#modal-login');

		if ($modalLogin) {

			let $btnToggleLogin = document.querySelector('.btn--login-modal');
			let $btnCloseLogin = $modalLogin.querySelector('.btn--modal-close');
			let $modalScroll = $modalLogin.querySelector('.modal-wrap');

			document.addEventListener('click', function (event) {
				let target = event.target;
				let link = target.closest('a.btn--login-modal');
				if (link && link.matches('.btn--login-modal')) {
					event.preventDefault();
					$modalLogin.classList.add('active');
					disableBodyScroll($modalScroll);
					return;
				}
			})

			$btnCloseLogin.addEventListener('click', () => {
				$modalLogin.classList.remove('active');
				enableBodyScroll($modalScroll);
			});

			// Trigger "esc" key to close
			document.addEventListener('keydown', (e) => {
				if (e.key === "Escape") {
					$modalLogin.classList.remove('active');
					enableBodyScroll($modalScroll);
				}
			});
		}
	},

	checkoutEvents: function () {

		$(document.body).on('update_checkout', function (error) {
			$(document.body).removeClass('wc-notice-error');
		});
		$(document.body).on('checkout_error', function (error) {
			$(document.body).addClass('wc-notice-error');
		});

		// let quoteSubmit = document.querySelector('#quote_place_order2');
		// if(quoteSubmit){
		// 	let quoteForm = document.querySelector('#adq-billing');
		// 	quoteSubmit.addEventListener('click', () => {
		// 		alert('heee');
		// 		// console.log(quoteForm);
		// 		// document.forms["adq-checkout"].submit();
		// 		quoteForm.submit();
		// 	});
		// }
	},

	ajaxFilters: function () {

		if (document.querySelectorAll('.ajax-form-filter').length == 0) {
			return;
		}
		let forms = document.querySelectorAll('.ajax-form-filter');
		for (var i = 0; i < forms.length; i++) {
			let form = forms[i];
			let refreshDivId = form.getAttribute('data-refresh');
			if (document.querySelectorAll('#' + refreshDivId).length > 0) {
				let refreshDiv = document.querySelector('#' + refreshDivId);
				let submitUrl = form.getAttribute('action');
				let loadingBloc = document.querySelector('#loading-pagination');

				if (form.classList.contains('wp-ajax')) {
					submitUrl = theme_infos.wp_ajax_url;
				}

				let currentObj = {};
				currentObj.xhr = false;
				let goFilter = function () {
					refreshDiv.classList.add('loading');
					refreshDiv.innerHTML = "";
					loadingBloc.classList.remove('visually-hidden');
					let formData = new FormData(form);
					formData.delete('volume-range'); // Cleaner query
					formData.delete('price-range'); // Cleaner query
					formData.append("is_ajax", "1");
					let stringDatas = new URLSearchParams(formData).toString();
					// console.log(stringDatas); // Debug
					if (currentObj.xhr !== false) {
						currentObj.xhr.abort();
					}
					currentObj.xhr = new XMLHttpRequest();

					let postUrl = submitUrl;
					if (!form.classList.contains('wp-ajax')) {
						postUrl = submitUrl + '?' + stringDatas;
					}
					currentObj.xhr.open("POST", postUrl);
					form.classList.add('loading');
					let formOffset = form.getBoundingClientRect().top;
					// Get pre-existing --sticky-offset CSS Vars
					let stickyHeaderOffset = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sticky-offset'));
					// Update Offset
					formOffset -= stickyHeaderOffset;

					window.scrollTo({
						'top': formOffset,
						behavior: 'smooth'
					});
					currentObj.xhr.onreadystatechange = function () {
						if (currentObj.xhr.readyState === 4) {
							loadingBloc.classList.add('visually-hidden');
							var template = document.createElement('template');
							template.innerHTML = currentObj.xhr.response;
							if (template.content.querySelector('#' + refreshDivId) !== null) {
								refreshDiv.innerHTML = template.content.querySelector('#' + refreshDivId).innerHTML;
							}
							refreshDiv.classList.remove('loading');
							// currentObj.ajaxPagination();
						}
					}
					currentObj.xhr.send(formData);
				}

				form.addEventListener('submit', function (event) {
					event.preventDefault();
					//goFilter();
					return false;
				});

				var inputs = form.querySelectorAll('input,select');
				for (let j = 0; j < inputs.length; j++) {
					inputs[j].addEventListener('change', function (event) {
						goFilter();
					});
				}

				var sortLabelBloc = form.querySelector('.current-label-sort');
				var inputOrder = form.querySelector('input[type="hidden"][name="order"]');
				var sortingLinks = form.querySelectorAll('.sorting__link');
				for (let j = 0; j < sortingLinks.length; j++) {
					let sortLink = sortingLinks[j];
					let order = sortLink.getAttribute('data-order');
					sortLink.addEventListener('click', function (event) {
						inputOrder.value = order;
						sortLabelBloc.innerText = sortLink.innerText;
						goFilter();
					});
				}

			}

		}
	},

	actionQuoteMail: function () {
		if (document.querySelectorAll('#send_email_quote').length == 0)
			return;

		let button = document.querySelector('#send_email_quote');
		button.addEventListener('click', (e) => {
			e.preventDefault();
			if (!button.classList.contains('loading')) {
				button.classList.add('loading');
				let xhr = new XMLHttpRequest();
				let params = 'action=send_email_quotation';
				xhr.open("POST", themeinfos.ajaxurl, true);
				xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
				xhr.onreadystatechange = function () {
					if (xhr.readyState === 4) {
						button.classList.remove('loading');
					}
				};
				xhr.onload = function () {
					if (xhr.status == 200) {
						if (this.responseText == "1") {
							let label = button.querySelector('.button--label');
							label.innerText = "Devis envoyé";
							button.disabled = true;
						} else {
						}
					}
				}
				xhr.send(params);
			}
			return false;

		});
	},
	customForms: function () {
		/***********************************
		 CHECKBOX GROUP (MULTIPLE)
		***********************************/
		let $checkboxGroups = document.querySelectorAll('.form-row.checkbox, .input--field.checkbox');

		if ($checkboxGroups) {
			Array.prototype.forEach.call($checkboxGroups, function ($checkboxGroup) {
				let $checkboxes = $checkboxGroup.querySelectorAll('input[type="checkbox"]');
				Array.prototype.forEach.call($checkboxes, function ($checkbox) {
					// Toggle previously checked checkbox
					if ($checkbox.checked == true) {
						$checkbox.closest('.form-row.checkbox, .input--field.checkbox').classList.add('checked');
					}
					// Toggle on change
					$checkbox.addEventListener('change', () => {
						$checkbox.closest('.form-row.checkbox, .input--field.checkbox').classList.toggle('checked');
					});
				});
			});
		}

		let $blockExpands = document.querySelectorAll('.filters-expand');
		if ($blockExpands) {
			Array.prototype.forEach.call($blockExpands, function ($blockExpand) {
				let $block = $blockExpand.closest('.filters-block');
				let innerWrap = document.createElement('div');
				innerWrap.classList.add('inner');
				let button = document.createElement('button');
				let items = Array.from($blockExpand.children);

				items.forEach(node => {
					innerWrap.appendChild(node);
				})
				$blockExpand.appendChild(innerWrap);

				button.innerHTML = "+";
				button.classList.add('btn--filters-expand');
				button.setAttribute('type', 'button');
				$block.appendChild(button);

				button.addEventListener('click', () => {
					if ($blockExpand.classList.contains('expand')) {
						$blockExpand.classList.remove('expand');
						$blockExpand.style.maxHeight = "";
						button.innerHTML = "+";
					} else {
						$blockExpand.classList.add('expand');
						$blockExpand.style.maxHeight = $blockExpand.querySelector('.inner').clientHeight + 'px';
						button.innerHTML = "-";
					}
				});
			});
		}


		/** RANGES */
		// let $ranges = document.querySelectorAll('input.custom-range');
		// if ($ranges) {
		// 	Array.prototype.forEach.call($ranges, ($range) => {
		// 		$range.addEventListener('change', () => {
		// 			console.log($range.value);
		// 		})
		// 	})
		// }

		// let $rangeVolume = document.querySelector('#example_id');
		// if ($rangeVolume) {
		// 	const myRangeSlider = IonRangeSlider('#example_id', {
		// 		min: 0,
		// 		max: 5000,
		// 		prefix: "$",
		// 		grid: true,
		// 		grid_num: 5,
		// 		step: 100,

		// 	});
		// }
		$("input.volume-range").ionRangeSlider({
			skin: "round",
			type: "double",
			min: 150,
			max: 3000,
			step: 250,
			postfix: "L",
			decorate_both: false,
			onFinish: function (data) {
				// Called then action is done and mouse is released

				let intStart = data.from;
				let intEnd = data.to;

				let input_dispatchEvent = '';

				let $volumeInputs = document.querySelectorAll('.filters-block.volume input[type="checkbox"]');
				Array.prototype.forEach.call($volumeInputs, ($volumeInput) => {
					let $volumeValue = $volumeInput.value;
					let volumeValueInt = $volumeValue.split('-')[0];
					volumeValueInt = parseInt(volumeValueInt);

					if (volumeValueInt >= intStart && volumeValueInt <= intEnd) {
						// console.log('check ' + $volumeValue);
						$volumeInput.checked = true;
						$volumeInput.closest('.input--field.checkbox').classList.add('checked');
					} else {
						$volumeInput.checked = false;
						$volumeInput.closest('.input--field.checkbox').classList.remove('checked');
					}

					$volumeInput.dispatchEvent(new Event('input', { 'bubbles': true })); // Efficient checkbox check
					// $volumeInput.dispatchEvent(new Event('change', { 'bubbles': true })); // Kills ajax - 500
					// Store input for later trigger
					input_dispatchEvent = $volumeInput;
				});

				// Hack to trigger "change" from last checkbox
				setTimeout(() => {
					input_dispatchEvent.dispatchEvent(new Event('change', { 'bubbles': true }));
				}, 100)
				// console.log(data.from);
				// console.log(data.to);
			}
		});

		$("input.price-range").ionRangeSlider({
			skin: "round",
			type: "double",
			// min: 0,
			// max: 3000,
			// step: 250,
			postfix: "€",
			decorate_both: false,
			onFinish: function (data) {
				let priceStart = data.from;
				let priceEnd = data.to;

				let priceStartInput = document.querySelector('.filters-variations #min_price');
				let priceEndInput = document.querySelector('.filters-variations #max_price');

				// Update values
				if (parseInt(priceStartInput.value) !== parseInt(priceStart)) {
					priceStartInput.value = parseInt(priceStart);
					priceStartInput.dispatchEvent(new Event('change', { 'bubbles': true }));
				}
				if (parseInt(priceEndInput.value) !== parseInt(priceEnd)) {
					priceEndInput.value = parseInt(priceEnd);
					priceEndInput.dispatchEvent(new Event('change', { 'bubbles': true }));
				}

			}
		});
	},

	wishlist: function () {
		// if(document.querySelectorAll("#product").length == 0 || !document.querySelector("#product").classList.contains('single-product'))
		// 	return;

		// let productid = document.querySelector("#product").getAttribute('data-id');

		document.addEventListener('click', (e) => {
			let button = e.target.closest('a,button');
			if (button && button.classList.contains('save-favoris')) {
				e.preventDefault();
				let userIsLogged = document.body.classList.contains('logged-in');
				if (userIsLogged) {
					let productid = button.dataset.productId;
					if (!button.classList.contains('loading')) {
						button.classList.add('loading');
						let xhr = new XMLHttpRequest();
						let params = 'action=save_favoris&product=' + productid;
						xhr.open("POST", themeinfos.ajaxurl, true);
						xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
						xhr.onreadystatechange = function () {
							if (xhr.readyState === 4) {
								button.classList.remove('loading');
							}
						};
						xhr.onload = function () {
							if (xhr.status == 200) {
								if (this.responseText == "1") {
									button.classList.add('is_fav');
									document.querySelector("#product").classList.add('is-wishlist');
								} else {
									button.classList.remove('is_fav');
									document.querySelector("#product").classList.remove('is-wishlist');
								}
							}
						}
						xhr.send(params);
					}
				} else {
					alert('Vous devez vous connecter')
				}
				return false;
			}

		});



	},

	stylingHandler: function () {

		/**
		 * Page Loaded
		 */
		window.addEventListener('load', function (event) {

			// Set proper Sticky Offsets
			// let adminOffset = document.documentElement.style.getPropertyValue('--top-offset');
			// adminOffset = parseInt(adminOffset.replace('px', ''));
			// let navHeight = this.document.querySelector('.header--pre').clientHeight;
			// console.log(navHeight, adminOffset);
			// let navOffset = adminOffset + navHeight;
			// document.documentElement.style.setProperty('--nav-offset', navOffset+'px');

			// Set same size for submenus
			let $submenus = document.querySelectorAll('.submenu-wrap');
			let maxWidthArray = [];
			Array.prototype.forEach.call($submenus, ($submenu) => {
				let submenuWidth = window.getComputedStyle($submenu).width.replace('px', '');
				maxWidthArray.push(Math.ceil(submenuWidth))
			});
			document.documentElement.style.setProperty('--submenu-width', Math.max.apply(Math, maxWidthArray) + 'px');

			setStickyOffset();

		});
		window.addEventListener('scroll', function (event) {
			setStickyOffset();
		});

		function setStickyOffset() {
			// Fix Sticky nav offset
			let is_logged = document.querySelector('#wpadminbar') ? true : false;
			let header = document.querySelector('.header--main-nav');

			if (!header) {
				return;
			}
			let headerHeight = header.clientHeight;
			let stickyOffset = is_logged ? 32 + headerHeight : headerHeight;
			document.documentElement.style.setProperty('--sticky-offset', stickyOffset + 'px');
		}

	},

	burgerNav: function () {

		/** BURGER TOGGLE MENU **/
		let $buttonBurger = document.querySelector('.btn--toggle-menu');
		let $navMain = document.querySelector('.header--main-nav');
		let $navMainUL = $navMain.querySelector('ul.menu');
		let $submenus = $navMainUL.querySelectorAll('.has-submenu');
		if (!$buttonBurger) {
			return;
		}

		function closeNav() {
			if ($buttonBurger.classList.contains('opened')) {
				$buttonBurger.classList.remove('opened');
				$buttonBurger.classList.add('closed');
				$navMain.classList.remove('opened');

				// console.log($submenus);
				Array.prototype.forEach.call($submenus, function ($submenu) {
					$submenu.classList.remove('expanded');
					$submenu.querySelector('.submenu-wrap').classList.remove('expanded');
				});
			}
		}

		// Trigger click-outside to close
		document.addEventListener('click', (e) => {
			if ($buttonBurger.classList.contains('opened') && e.target !== $navMainUL && !$navMainUL.contains(e.target) && e.target !== $buttonBurger && !$buttonBurger.contains(e.target)) {
				closeNav();
			}
		});

		// Trigger "esc" key to close
		document.addEventListener('keydown', (e) => {
			if (e.key === "Escape") {
				closeNav();
			}
		});

		let toggleBurger = function (e) {
			/* Toggle Button */
			if ($buttonBurger.classList.contains('opened')) {
				closeNav()
				// $buttonBurger.classList.remove('opened');
				// $buttonBurger.classList.add('closed');
			} else {
				$buttonBurger.classList.add('opened');
				$buttonBurger.classList.remove('closed');
				$navMain.classList.add('opened');
			}
			/* Toggle Nav wrapper */
			// Close all Submenus
			//   let $submenus = $navMain.querySelectorAll('.has-megamenu, .has-submenu, .header--account');
			//   for(let i = 0; i < $submenus.length; i++) {
			// 	let $submenu = $submenus.item(i);
			// 	$submenu.classList.remove('opened', 'visible');
			//   }

			//   if( $buttonBurger.classList.contains('opened') ){
			// 	// disableBodyScroll(menuBS);
			//   } else {
			// 	// enableBodyScroll(menuBS);
			//   }
		}

		$buttonBurger.addEventListener('click', toggleBurger, false);

		/** SUBMENUS */
		let $menuParents = document.querySelectorAll('li.has-submenu');
		if ($menuParents) {
			Array.prototype.forEach.call($menuParents, function ($menuParent) {
				let submenuHeight = $menuParent.querySelector('.submenu-wrap').scrollHeight;
				// console.log($menuParent.querySelector('.submenu-wrap'), submenuHeight)
			});
		}

		/** MANAGE SUBMENU **/
		let $submenusAncestors = document.querySelectorAll('.has-submenu > a');

		let $menuReturn = document.createElement('li');
		$menuReturn.classList.add('submenu-return');
		$menuReturn.innerHTML = '&lt; Retour';

		// Set & Open MEGAMENU
		for (let i = 0; i < $submenusAncestors.length; i++) {
			let $submenuParent = $submenusAncestors.item(i);


			// $menuExpand.classList.add('icon-expand');
			// $submenuParent.querySelector('a').appendChild($menuExpand);


			$submenuParent.addEventListener('click', function (e) {

				e.preventDefault();
				let target = e.target.closest('a');

				// if( e.target.classList.contains('icon-expand') ){
				// 	//alert('expand');
				// 	//e.preventDefault();
				// 	toggleExpand(e.target);
				// 	return;
				// } else if( e.target.classList.contains('parent-link') ){
				if (target.classList.contains('btn-menu') && getViewportWidth() < headerBreakpoint) {
					// alert('link!');
					let $parentMenu = target.closest('li.has-submenu');
					let $parentMenuIsOpened = $parentMenu.classList.contains('expanded') ? true : false;
					// let $expand = e.target.querySelector('.icon-expand');

					// console.log($parentMenuIsOpened);

					if (!$parentMenuIsOpened) {
						//e.preventDefault()
						toggleExpand($parentMenu);
					} else {
						window.location = target.href;
					}

				} else {
					window.location = target.href;
				}


				/*let $submenu = $submenuParent.querySelector('.submenu');
				//let submenuHeight = $submenuParent.dataset.height;

				$submenuParent.classList.add('opened');
				//$submenu.classList.add('opened');
				$submenu.style.maxHeight = $submenuHeight + 'px';
				//this.style.height = submenuHeight + 'px';*/

				//}

			});

		}

		document.addEventListener('click', (e) => {
			if (e.target.classList.contains('submenu-return')) {
				e.preventDefault();
				let currentMenu = e.target.closest('.has-submenu');
				currentMenu.classList.remove('expanded')
				currentMenu.querySelector('.submenu-wrap').classList.remove('expanded')
			}
		});

		function toggleExpand($parent) {
			// console.log($parent)
			// let $parent = $menu.closest('li.has-submenu');
			let $submenu = $parent.querySelector('.submenu-wrap');
			let $sub = $submenu.querySelector('ul');
			// let $submenuItems = $submenu.querySelectorAll('li');
			// let $submenuHeight = 0;

			// for(let i = 0; i < $submenuItems.length; i++) {
			// 	let $submenuItem = $submenuItems.item(i);
			// 	$submenuHeight += $submenuItem.clientHeight;
			// };
			$sub.insertBefore($menuReturn, $sub.firstChild);

			if ($parent.classList.contains('expanded')) {
				$parent.classList.remove('expanded');
				$submenu.classList.remove('expanded');
				// item.parentNode.nextElementSibling.style.maxHeight = '';
			} else {
				$parent.classList.add('expanded');
				$submenu.classList.add('expanded');
				// $submenu.style.maxHeight = $submenuHeight + 'px';
			}

		}
	},

	stickyNav: function () {

		let $header = document.querySelector('.header--main');
		let $nav = $header.querySelector('.header--main-nav');

		document.addEventListener("scroll", function () {
			let currentScroll = window.scrollY;
			let headerBottom = $header.getBoundingClientRect().bottom;
			let navBottom = $nav.getBoundingClientRect().bottom;

			if (headerBottom <= 0 && getViewportWidth() > headerBreakpoint) {
				$nav.classList.add('sticky');
			} else {
				$nav.classList.remove('sticky');

			}

			// console.log(navBottom, window.scrollY)
		}, { passive: true });


		// let $header = document.querySelector('.header--main');

		// // Create #progress
		// let $progress = document.createElement('div');
		// let $progressBar = document.createElement('div');
		// $progress.id = 'progress';
		// $progressBar.id = 'progress-bar';
		// $progress.appendChild($progressBar);
		// $header.appendChild($progress);

		// document.addEventListener("scroll",	function() {
		// 	let headerHeight = $header.clientHeight;

		// 	let $post = document.querySelector('.post--single');
		// 	let postOffsetTop = $post.getBoundingClientRect().top - headerHeight;
		// 	let postHeight = $post.clientHeight;

		// 	if(postOffsetTop < 0){
		// 		let scrollPercent = -postOffsetTop / postHeight * 100 + "%";
		// 		$progress.style.setProperty("opacity", 1);
		// 		$progressBar.style.setProperty("width", scrollPercent);
		// 	} else {
		// 		$progress.style.setProperty("opacity", 0);
		// 		$progressBar.style.setProperty("width", 0); // make sure #progress reset to 0 with fast scrolling
		// 	}
		// },{ passive: true });
	},

	backToTop: function () {

		let $btnTop = document.querySelector('.btn--return-top');
		if ($btnTop) {


			$btnTop.addEventListener('click', () => {
				window.scrollTo({
					'top': 0,
					behavior: 'smooth'
				});
			});

			window.addEventListener('scroll', function (event) {
				// setStickyOffset();
				let topOffset = window.pageYOffset;

				if (topOffset < 200) {
					$btnTop.classList.add('hidden');
				} else {
					$btnTop.classList.remove('hidden');
				}

			});

		}
	},

	// trophyBottomBanner: function() {

	// 	let banner = document.querySelector('.footer-trophy-banner');
	// 	setTimeout(() => {
	// 		banner.classList.add('visible');
	// 	}, 5000);
	// }

	// wooCart: function(){
	// 	$( window ).on('wc_cart_emptied', function () {
	// 		// console.log('hello')
	// 		$( window ).trigger( 'wc_fragment_refresh' );
	// 		// refresh_fragments();
	// 	});
	// }

};
mainHandler.init();

/** WOOCOMMERCE - All pages */
let wcHandler = {
	init: function () {
		this.shopTables();

		$(document.body).on('updated_checkout', function () {
			wcHandler.shopTables();
		});

		$(document.body).on('checkout_error', function () {
			jQuery('html, body').stop();
			let offset = document.querySelector('.header--main').offsetHeight - 1;
			console.log(offset);
			window.scrollTo({
				'top': offset,
				behavior: 'smooth'
			});
		});

		let registerFieldPwd = document.querySelectorAll('input.reg_password');
		if (registerFieldPwd) {
			Array.prototype.forEach.call(registerFieldPwd, (fieldPwd) => {

				let btnPwd = fieldPwd.closest('.form-row').querySelector('.wp-hide-pw');

				fieldPwd.addEventListener('input', (e) => {
					// console.log(e.target.value)
					if (e.target.value !== '') {
						btnPwd.style.display = 'block';
					} else {
						btnPwd.style.display = 'none';
					}
				});

				fieldPwd.addEventListener('blur', (e) => {
					// console.log(e.target.value)
					if (e.target.value !== '') {
						btnPwd.style.display = 'block';
					} else {
						btnPwd.style.display = 'none';
					}
				});

				btnPwd.addEventListener('click', () => {
					if (fieldPwd.getAttribute("type") === "password") {
						fieldPwd.setAttribute("type", "text");
						btnPwd.querySelector('.dashicons').setAttribute("class", "dashicons dashicons-hidden");
					} else {
						fieldPwd.setAttribute("type", "password");
						btnPwd.querySelector('.dashicons').setAttribute("class", "dashicons dashicons-visibility");
					}

				})
			})
		}
		// jQuery( document ).ajaxComplete( function() {
		// 	if ( jQuery( 'body' ).hasClass( 'woocommerce-checkout' ) || jQuery( 'body' ).hasClass( 'woocommerce-cart' ) ) {
		// 		jQuery( 'html, body' ).stop();
		// 	}
		// } );
	},
	shopTables: function () {
		// Mobile custom layout
		let $tables = document.querySelectorAll('.shop_table_responsive');
		if ($tables) {

			let $labels = document.querySelectorAll('.col-label');

			if ($labels.length === 0) {
				Array.prototype.forEach.call($tables, function ($table) {
					let $tableHeads = $table.querySelectorAll('th');
					let $tableRows = $table.querySelectorAll('tr');
					let labels = [];

					Array.prototype.forEach.call($tableHeads, function ($tableHead) {
						let columnTitle = $tableHead.innerText;
						labels.push(columnTitle);
					});

					Array.prototype.forEach.call($tableRows, function ($tableRow) {
						let index = 0;
						let $tableCols = $tableRow.querySelectorAll('td[data-title]');
						Array.prototype.forEach.call($tableCols, function ($tableCol) {
							let $columnLabel = document.createElement('span');
							$columnLabel.classList.add('col-label');
							// $columnLabel.innerText = labels[index];
							$columnLabel.innerText = $tableCol.dataset.title;
							$tableCol.insertBefore($columnLabel, $tableCol.firstChild);
							index++;
						});
					});
				});
			}

		}
	}
}
if (document.querySelector('body.woocommerce-page, body.woocommerce-js')) {
	wcHandler.init();
}

/**
 * WOOCOMMERCE - Cart
 */
let cartHandler = {
	init: function () {
		this.autoUpdate();
	},
	autoUpdate: function () {
		$('.woocommerce').on('change', 'input.qty', function () {
			$("[name='update_cart'], [name='update_quote']").trigger("click");
		});
	}
}
if (document.querySelector('body.woocommerce-cart')) {
	cartHandler.init();
}

/**
 * WOOCOMMERCE - Checkout
 */
let checkoutHandler = {
	init: function () {
		this.expandAccount();

		window.addEventListener('load', function (event) {
			let $reviewOrder = document.querySelector('.wc-block.order-review');
			let reviewOrderHeight = $reviewOrder.clientHeight;
			let $checkoutWrap = document.querySelector('body.woocommerce-checkout .page-content .wrap');
			$checkoutWrap.style.minHeight = reviewOrderHeight + 20 + 'px';

		});

		jQuery('body').on('checkout_error', function () {
			// console.log('checkout_error triggered');
			let $noticesGroup = document.querySelector('.woocommerce-NoticeGroup');
			if ($noticesGroup) {
				let noticesHeight = $noticesGroup.clientHeight;
				// alert(noticesHeight);
				document.documentElement.style.setProperty('--notices-offset', `${noticesHeight + 20}px`);

				let $reviewOrder = document.querySelector('.wc-block.order-review');
				let reviewOrderHeight = $reviewOrder.clientHeight;
				let $checkoutWrap = document.querySelector('body.woocommerce-checkout .page-content .wrap');
				$checkoutWrap.style.minHeight = noticesHeight + reviewOrderHeight + 20 + 'px';
			}
			// now.do.whatever();
		});

	},
	expandAccount: function () {
		let $btnExpand = document.querySelector('.btn-expand-checkout');
		if ($btnExpand) {
			let $expand = document.querySelector('.wrap-expand');
			let $blockLogin = document.querySelector('.wc-block.login');
			let $blockRegisterTitle = document.querySelector('.wc-block.register .block-title');
			$btnExpand.addEventListener('click', (e) => {
				$expand.classList.remove('hidden');
				$btnExpand.style.display = 'none';
				$blockRegisterTitle.style.display = 'none';
				$blockLogin.classList.add('hidden');
			});
		}
	}
}
if (document.querySelector('body.woocommerce-checkout'))
	checkoutHandler.init();

/**
 * WOOCOMMERCE - My Account
 */
let myAccountHandler = {
	init: function () {
		this.accountNav();

		this.resizeHandler();

		window.addEventListener('load', function (event) {
			// Block `showlogin` link on Login
			let $showlogin = document.querySelector('.showlogin');
			if ($showlogin) {
				$showlogin.addEventListener('click', (e) => {
					// console.log('stop');
					return false;
				});
			}
		});
	},
	resizeHandler: function () {

		let vw = getViewportWidth();

		if (vw <= mobileBreakpoint) {
			this.shopTables();
		}

		window.addEventListener('resize', () => {
			vw = getViewportWidth();
			if (vw <= mobileBreakpoint) {
				this.shopTables();
			} else {
				let $labels = document.querySelectorAll('.col-label');
				if ($labels)
					Array.prototype.forEach.call($labels, function ($label) {
						$label.remove()
					});
			}

		});
	},
	accountNav: function () {

		let $nav = document.querySelector('.myaccount--wrap-navigation');
		let $navWrap = '';
		let $navItems = '';
		let $toggleAccountNav = '';

		if ($nav) {

			$navWrap = $nav.querySelector('.nav-wrap');
			$navItems = $nav.querySelector('ul');
			$toggleAccountNav = $nav.querySelector('.btn--account-nav');

			// Trigger click-outside to close
			document.addEventListener('click', (e) => {
				if (e.target.matches('.btn--account-nav') || e.target.matches('.nav-wrap') || $navWrap.contains(e.target))
					return;

				if ($toggleAccountNav.classList.contains('opened'))
					toggleAccountNav();
			});

			$toggleAccountNav.addEventListener('click', () => {
				toggleAccountNav();
			});

		}

		function toggleAccountNav() {
			$toggleAccountNav.classList.toggle('opened');
			$navWrap.classList.toggle('opened');
			if ($toggleAccountNav.classList.contains('opened')) {
				let navHeight = $navItems.scrollHeight;
				$navWrap.style.maxHeight = navHeight + 'px';
			} else {
				$navWrap.style.maxHeight = '';
			}
		}
	},
	shopTables: function () {

		// Mobile custom layout
		let $tables = document.querySelectorAll('.shop_table_responsive');
		if ($tables) {

			let $labels = document.querySelectorAll('.col-label');

			if ($labels.length === 0) {
				Array.prototype.forEach.call($tables, function ($table) {
					let $tableHeads = $table.querySelectorAll('th');
					let $tableRows = $table.querySelectorAll('tr');
					let labels = [];

					Array.prototype.forEach.call($tableHeads, function ($tableHead) {
						let columnTitle = $tableHead.innerText;
						labels.push(columnTitle);
					});

					Array.prototype.forEach.call($tableRows, function ($tableRow) {
						let index = 0;
						let $tableCols = $tableRow.querySelectorAll('td');
						Array.prototype.forEach.call($tableCols, function ($tableCol) {
							let $columnLabel = document.createElement('span');
							$columnLabel.classList.add('col-label');
							$columnLabel.innerText = labels[index];
							$tableCol.insertBefore($columnLabel, $tableCol.firstChild);
							index++;
						});
					});
				});
			}

		}
	}
}
if (document.querySelector('body.woocommerce-account')) {
	myAccountHandler.init();
}

// });

/**
 * UTILITIES
 */
function getViewportWidth() {
	let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
	return viewportWidth;
}
function getNodeindex(el) {
	if (!el) return -1;
	let i = 0;
	while (el = el.previousElementSibling) {
		i++;
	}
	return i;
}
