// const bodyScrollLock = require('body-scroll-lock');
// const disableBodyScroll = bodyScrollLock.disableBodyScroll;
// const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export function mobileBreakpoint() {
    return 768;
}

export function headerBreakpoint() {
    return 1024;
}

export const mobileHandler = {
    init: () => {

        mobileHandler.isMobile();
        mobileHandler.setRealVH();

        window.addEventListener('resize', () => {
            // console.log("resize");
            mobileHandler.isMobile();
            mobileHandler.setRealVH();
        });

    },

    isMobile: () => {
        let isMobile = getViewportWidth() < mobileBreakpoint();
        if (isMobile) {
            document.body.classList.add('mobile');
        } else {
            document.body.classList.remove('mobile');
        }
    },

    setRealVH: () => {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
}

export function getViewportWidth() {
    let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    return viewportWidth;
}