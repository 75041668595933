import Plyr from 'plyr';

export const videosHandler = {
    init: () => {
        videosHandler.setPlayers();
    },
    setPlayers: () => {

        let $players = document.querySelectorAll('.player-wrap');

        if ($players) {
            Array.prototype.forEach.call($players, ($playerWrap) => {
                let plyr = new Plyr($playerWrap.querySelector('.player'), {
                    iconPrefix: 'plyr_c',
                    youtube: {
                        noCookie: true
                    }
                });
            })
        }

    }
};