export const uiHandler = {
    init: () => {
        if (document.querySelector('[data-animate-show]')) {
            uiHandler.animate();
        }
        // if (document.querySelector('[data-counter-number]')) {
        //     uiHandler.textCounter();
        // }
    },
    textCounter: () => {
        const counters = document.querySelectorAll('.keyfigure-value-nb');
        const speed = 600;

        counters.forEach(counter => {
            const animate = () => {
                const value = +counter.dataset.counterNumber;
                const data = +counter.innerText;

                const time = value / speed;
                if (data < value) {
                    counter.innerText = Math.ceil(data + time);
                    setTimeout(animate, 1);
                } else {
                    counter.innerText = value;
                }

            }

            animate();
        });
    },
    animate: () => {
        window.addEventListener("scroll", onScroll);

        for (var item of document.querySelectorAll("[data-animate-show]")) {
            if (item.offsetTop > window.scrollY) {
                elementVisible(item);
            }
        }
        function onScroll() {
            for (var item of document.querySelectorAll("[data-animate-show]")) {
                elementVisible(item);
            }
        }

        function elementVisible(el) {
            let top = el.offsetTop;
            let height = el.offsetHeight;
            let bottom = top + height;

            let IsOverBottom = top > (window.pageYOffset + (3 * window.innerHeight / 4));
            let IsBeforeTop = bottom < window.pageYOffset;

            if (!IsOverBottom && !IsBeforeTop) {
                el.classList.add("show");
                if (el.querySelector('[data-counter-number]')) {
                    uiHandler.textCounter();
                }
            }
        }

    }
}